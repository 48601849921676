$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@mixin plus-rectangle {
  content: '';
  position: absolute;
  width: 1px;
  height: 7px;
  background-color: #5d78ff;
  z-index: 5;
}

@mixin button-reset {
  padding: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}



@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .m-#{$space} {
      margin: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
    .p-#{$space} {
      padding: #{$space}px !important;
    }
  }
}