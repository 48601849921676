.waybill-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  background: linear-gradient(0.06deg, #ffffff 0.06%, #ffffff 97.56%);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  &__info {
    display: flex;
    align-items: center;
    padding-right: 10px;
    .info-holder {
      display: flex;
      align-items: center;
      &__icon {
        flex-basis: 38px;
        max-width: 38px;
        padding-right: 10px;
        svg {
          max-width: 100%;
        }
      }
      &__text {
        .title {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap;
          color: #57565b;
        }
        .breadcrumbs {
          display: flex;
          li {
            padding: 0 7px;
            position: relative;
            &:first-child {
              padding-left: 0;
            }
            a,
            span {
              white-space: nowrap;
              font-weight: 400;
              font-size: 10px;
              line-height: 12px;
              color: #95949c;
            }
            a:hover {
              text-decoration: underline;
            }
            &:not(:last-child) {
              &::after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                width: 6px;
                height: 10px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAwLjg2NzAwNEw0LjEzMyA0TDEgNy4xMzMiIHN0cm9rZT0iIzk1OTQ5QyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
                background-size: 6px 10px;
                transform: translate(50%, -50%);
              }
            }
          }
        }
      }
    }
    .status-holder {
      margin-left: 25px;
    }
  }
  &__search {
    flex: 1;
  }
  &__action {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
