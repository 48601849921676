.product-image {
  width: 45px;
  height: 45px;
  border-radius: 3px;
  overflow: hidden;
  background-position: center;
  background-size: contain;
  border: 1px solid #d1d5dc;
  background-color: #ffffff;
  position: relative;
  font-size: 0;
  line-height: 0;
  z-index: 0;
  &__count {
    display: inline;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 3px 0 3px 0;
    min-width: 22px;
    font-size: 11px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
    pointer-events: none;
    padding: 0 7px;
    background-color: #fcfcfc;
    color: #000;
  }
}
