.order-status {
  display: flex;
  align-items: center;
  margin-right: 8px;
  &__box {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 5px;
  }
  &__name {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }
}

