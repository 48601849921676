.main-tabs {
  display: flex;
  background-color: #ffffff;
  padding: 0 10px;
  border-bottom: 1px solid #ebedf2;
  &+.main-wrapper{
    min-height: calc(100vh - 145px);
  }
  .tab {
    background-color: #ffffff;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: transparent;
    padding: 9px;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background-color: #5d78ff;
      height: 1px;
      opacity: 0;
      transition: 0.2s;
    }
    &:hover {
      background-color: #f0f3ff;
    }
    &.active {
      background-color: #f0f3ff;
      border-color: #ebedf2;
      &:hover {
        background-color: #f0f3ff;
      }
      &:after {
        opacity: 1;
      }
    }
  }
}

.panel-tabs{
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid #ebedf2;
  .tab {
    min-width: 60px;
    background-color: #ffffff;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: transparent;
    padding: 9px;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background-color: #5d78ff;
      height: 1px;
      opacity: 0;
      transition: 0.2s;
    }
    &:hover {
      background-color: #f0f3ff;
    }
    &.active {
      background-color: #f0f3ff;
      border-color: #ebedf2;
      &:hover {
        background-color: #f0f3ff;
      }
      &:after {
        opacity: 1;
      }
    }
  }
}