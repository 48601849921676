.error-box {
  &:not(:last-child) {
    margin: 0 0 10px;
  }
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #d1d5dc;
  &__header {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    justify-content: space-between;
    background: #fcfcfc;
    .icon {
      margin-right: 5px;
    }
    span,
    strong {
      font-size: 13px;
      line-height: 20px;
      color: #192027;
    }
    strong {
      font-weight: 500;
    }
  }
  &__body {
    li {
      padding: 8px 10px;
      border-top: 1px solid #d1d5dc;
      .title {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
