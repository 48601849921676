.tag {
  display: inline-block;
  font-size: 11px;
  line-height: 21px;
  padding: 0 5px;
  font-weight: 600;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  background-color: #adb0b7;
  margin: 2px;

  &--success {
    background-color: #1c8752;
  }

  &--warning {
    background-color: #eb840f;
  }

  &--danger {
    background-color: #d54839;
  }

  &--info {
    background-color: #0077da;
  }
  &--purple {
    background-color: #ae22ff;
  }
  
  &--clickable {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
}
