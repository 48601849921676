.order-status-select {
  display: flex;
  align-items: center;
  .order-status {
    display: flex;
    align-items: center;
    &__box {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 5px;
      margin-right: 4px;
    }
    &__name {
      font-size: 11px;
      line-height: 15px;
      font-weight: 500;
    }
  }
  
  button {
    display: inline-flex;
    justify-content: center;
    min-width: 70px;
    text-align: center;
    height: 30px;
    padding: 4px;
    border: 1px solid #d7d7da;
    border-radius: 0 5px 5px 0;
    margin-left: -1px;
    transition: 0.2s;
    span {
      font-size: 12px;
      line-height: 20px;
      transition: 0.2s;
      color: #192027;
    }
    &:hover {
      background-color: #192027;
      span {
        color: #fff;
      }
    }
    &:active {
      transform: scale(0.95);
    }
    &[disabled]{
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
