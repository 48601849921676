.main-table {
  width: 100%;
  border-bottom: 1px solid #ebedf2;
  transition: 0.2s;
  &__head {
    th {
      padding: 8px 0 8px 10px;
      text-align: left;
      vertical-align: middle;
      span {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }
  &__body {
    tr {
      border-top: 1px solid #ebedf2;
      transition: 0.2s;
      &:hover {
        background-color: #f9f9f9;
      }
      td {
        padding: 5px 0 5px 10px;
        font-size: 12px;
        line-height: 18px;
        vertical-align: middle;
        color: #595d6e;
      }
    }
  }
}
