.label {
  display: inline-block;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.81px;
  border-radius: 2px;
  padding: 4px 8px;
  background: #adb0b7;
  color: #fff;
  font-weight: 300;
  margin: 2px;
  &-icon {
    padding: 3px 4px 2px;
    font-size: 10px;
  }
  &-sm {
    padding: 3px 4px 2px;
    font-size: 10px;
  }
  &-bold {
    font-weight: 500;
  }
  &-round {
    border-radius: 4px;
  }
  &-up {
    text-transform: uppercase;
  }
  &--danger {
    background-color: #c70000;
  }
  &--warning {
    background-color: #eb840f;
  }
  &--info {
    background-color: #499fd8;
  }
  &--success {
    background-color: #1c8752;
  }
  &--purple {
    background-color: #ae22ff;
  }
}
