.flag-mr-10 {
  margin-right: 10px;
}

.mlr-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mtb-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.bg-none {
  background-color: transparent !important;
}

.fw-300 {
  font-weight: 300;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}

.text-10 {
  font-size: 10px !important;
}

.text-8 {
  font-size: 8px !important;
}

.text-light {
  color: #a2a5b9 !important;
}
.text-normal {
  font-weight: 400;
}
.df {
  display: flex;
}
.h-space {
  justify-content: space-between !important;
}
.h-start {
  justify-content: flex-start !important;
}
.h-end {
  justify-content: flex-end !important;
}

.v-start {
  align-items: flex-start !important;
}
.v-end {
  align-items: flex-end !important;
}
.v-center {
  align-items: center!important;
}
