.btn-more-group {
  position: relative;
  font-size: 0;
  line-height: 0;
  .btn {
    padding: 2px 5px;
    svg {
      margin: 0;
    }
  }
  .btn-more-panel {
    display: none;
    &.open {
      display: block;
    }
    position: absolute;
    z-index: 1;
    border-radius: 5px;
    right: 0;
    top: 100%;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 4px 0;
    ul {
      li {
        padding: 2px 5px;
        button {
          text-align: center;
          cursor: pointer;
          border-radius: 5px;
          display: inline-block;
          width: 100%;
          padding: 10px;
          transition: 0.2s;
          &:hover {
            background-color: #f9f9f9;
          }
        }
      }
    }
  }
}
