.main-search {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  &__icon {
    position: absolute;
    left: 0;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #737d88;
  }
  &__input {
    width: 100%;
    line-height: 40px;
    height: 40px;
    padding: 0 15px 0 50px;
    color: #737d88;
    border: 1px solid #dee0e6;
    border-radius: 50px;
  }
  &__submit {
    position: absolute;
    top: 2px;
    right: 3px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #737d88;
    background-color: #ffffff;
    transition: 0.2s;
    &:hover {
      background-color: #ecf4ff;
      color: #408ff7;
    }
  }
}
