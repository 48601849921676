.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.047);
  border-radius: 5px;
  margin: 10px 0 0;
  &.full {
    height: calc(100% - 10px);
  }
  &__tabs {
    display: flex;
    background-color: #f2f3f8;
    .tab {
      flex: 1;
      background-color: #ebecf3;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:last-child {
        &::before {
          display: none;
        }
      }
      a {
        display: block;
        padding: 9px 0;
      }
      &:hover {
        background-color: #e5e6ef;
      }
      &.active {
        background-color: white;
        position: relative;
        &:hover {
          background-color: white;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #5d78ff;
          height: 2px;
        }
        &:before {
          display: none;
        }
      }
    }
  }
  &__footer {
    border-top: 1px solid $separator;
    display: flex;
    padding: 10px 20px;
    .button-group {
      display: flex;
      align-items: center;
      button {
        margin: 0 5px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__header {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    min-height:51px;
    align-items: center;
    border-bottom: 1px solid $separator;
  }
  &__title {
    margin-right: auto;
    white-space: nowrap;
  }
  &__list {
    &.scroll{
      overflow-y: scroll;
    }
    li {
      padding: 10px 20px;
      border-bottom: 1px solid $separator;
    }
  }
}

.main-dashboard {
  min-height: calc(100vh - 40px);
  background-color: $dashboard-background;
}

.selectbox {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f3ff 0% 0% no-repeat padding-box;
  padding: 4px 10px;
  padding-left: 4px;
  text-align: left;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #93a2dd;
}
