.popup__body {
  padding: 20px 30px;
  p {
    font-size: 12px;
    line-height: 20px;
    color: #48465b;
  }
  .global_image_modal-box {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    .image {
      flex-basis: 40px;
      max-width: 40px;
      img {
        max-width: 100%;
      }
    }
    .text {
      flex: 1;
      padding-left: 15px;
      .product-name {
        font-weight: 300;
      }
      .sku {
        font-weight: 300;
        color: #a2a5b9;
      }
    }
  }
}
