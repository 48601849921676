.orders-layout {
  display: flex;

  .statuses-list {
    flex-basis: 200px;
    max-width: 200px;
    max-height: calc(100vh - 96px);
    background-color: #f6f7f9;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .loader {
      display: flex;
      justify-content: center;
      padding: 10px;
    }

    .divider {
      border-bottom: 1px solid #c5c4cd;
    }

    a {
      display: block;
      transition: 0.2s;
      color: #48465b;
      padding: 4px 10px;
      font-size: 12px;
      line-height: 14px;

      &:hover {
        background-color: #d6d4de;
      }

      &.lg {
        padding: 8.5px 10px;
      }
    }

    .status-group {
      &.open {
        .status-group__header {
          svg {
            transform: scaleY(-1);
          }
        }
      }

      &__list {
        margin-top: 5px;
      }

      &__header {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        padding: 5px 10px;

        svg {
          margin-left: 5px;
          transition: 0.2s;
        }
      }

      ul {
        li {
          line-height: 0;
          position: relative;

          &:hover,
          &.active {
            background-color: #d6d4de;
          }

          a {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            min-width: 100%;
            padding-right: 10px;
          }

          .pill {
            display: inline-block;
            line-height: 14px;
            margin: 0 3px 0 0;
            padding: 0 2px;
            letter-spacing: 0.4px;
            min-width: 17px;
            font-size: 11px;
            border-radius: 3px;
            margin-right: 4px;
            text-align: center;
            color: #fff;
          }

          p {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: #192027;
          }

          .status-name {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.35px;
            white-space: nowrap;
          }

          .status-badge {
            position: absolute;
            z-index: 2;
            width: 8px;
            height: 8px;
            top: calc(50% - 3px);
            right: 5px;

            &__dot {
              width: 100%;
              height: 100%;
              background-color: #1c8752;
              border-radius: 50%;
              animation-name: pulse;
              animation-duration: 2s;
              animation-fill-mode: both;
              animation-iteration-count: infinite;
            }
          }
        }
      }
    }
  }

  .order-box {
    flex: 1;
    max-height: calc(100vh - 96px);
    padding-bottom: 10px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main-list {
    &__table {
      table {
        .table-sort th {
          padding: 13px 6px 10px;
        }

        tbody tr td {
          vertical-align: top;
          padding: 4px 6px;
        }
      }
    }

    .order-identifier {
      font-size: 12px;
    }

    .country-flag {
      font-size: 15px;
      line-height: 15px;
      margin-right: 5px;
    }

    .btn-collapse-lines {
      padding: 0;
      background: none;
      color: #0077da;
      font-size: 11px;
      line-height: 15px;
      margin: 2px 0 0 5px;
    }

    .order-line {
      display: flex;
      margin: 2px 0;

      .react-tooltip {
        z-index: 1;
        opacity: 1;
      }

      .box-image {
        flex-basis: 60px;
      }

      .box-text {
        flex: 1;
        padding-left: 6px;

        &__qty {
          font-weight: 600;
        }

        &__codes {
          &>span {
            &:not(:last-child)::after {
              content: " | ";
            }
          }
        }
      }
    }
  }

  .order-details {
    padding-right: 10px;

    &__header {
      margin-top: 10px;
      padding: 10px;
      display: flex;
      align-items: baseline;

      h1 {
        span {
          color: #4e565f;
          font-size: 20px;
          line-height: 35px;
          font-weight: 300;
        }

        padding-right: 20px;
      }

      .clipboard-text {
        color: #4e565f;
      }

      p {
        font-size: 12px;
        color: #4e565f;
      }
    }

    &__info {
      padding: 10px;

      .payment-status {
        display: flex;
        align-items: center;
        padding: 0 10px 10px;
        border-bottom: 1px solid #ebedf2;

        &__label {
          flex-basis: 130px;
          line-height: 21px;
        }

        &__info {
          display: flex;
          align-items: center;

          .tag {
            margin-right: 10px;
          }

          span {
            line-height: 21px;
          }

          .total_price_in_pln {
            margin-left: 5px;
            color: #595d6e;
          }

          .price-buttons {
            display: flex;
            margin-left: 5px;
            align-items: center;

            button {
              margin: 0 5px;
              padding: 2px 10px;
              font-size: 12px;

              svg {
                display: none;
              }
            }
          }

          input {
            width: 80px;
            border: 1px solid #d1d5dc;
            height: 27px;
            line-height: 25px;
            font-size: 13px;
            padding: 0 12px;
            border-radius: 4px;
            color: #2d353d;
            margin-right: 10px;

            &:focus-visible,
            &:focus {
              border: 1px solid #0077da;
            }
          }
        }
      }

      .check-group {
        margin: 0 0 0 7px;

        .label-form {
          color: #2d353d;
        }
      }

      .info-group {
        width: 100%;

        &:not(:last-child) {
          border-bottom: 1px solid #ebedf2;
        }

        tr {
          &:first-child {
            td {
              padding-top: 10px;
            }
          }

          &:last-child {
            td {
              padding-bottom: 10px;
            }
          }

          td {
            padding: 2px 5px 2px 10px;

            &:first-child {
              width: 130px;
            }

            .editable {
              position: relative;
              z-index: 0;
              transition: 0.2s;
              text-align: left;

              .icon {
                opacity: 0;
                position: absolute;
                left: -16px;
                color: #0077da;
              }

              &:hover {
                text-decoration: underline;
                color: #0077da;

                .icon {
                  opacity: 1;
                }
              }
            }

            &>input {
              width: 100%;
              border: 1px solid #d1d5dc;
              height: 27px;
              line-height: 25px;
              font-size: 13px;
              padding: 0 12px;
              border-radius: 4px;
              color: #2d353d;

              &:focus-visible,
              &:focus {
                border: 1px solid #0077da;
              }
            }
          }
        }
      }
    }

    &__alert {
      margin-top: -1px;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      border-style: solid;
      border-width: 1px 0 1px 0;
      background-color: #fff;
      border-color: #ebedf2;

      .alert-icon {
        flex-basis: 35px;
        font-size: 20px;
      }

      .alert-content {
        flex: 1;

        svg {
          margin-right: 5px;
        }

        p {
          font-size: 13px;
          line-height: 21px;

          b {
            font-weight: 500;
          }
        }
      }

      &.warning {
        border-color: #f6cea6;
        background-color: rgb(254, 245, 235);

        .alert-icon {
          color: #cf740d;
        }

        .alert-content {
          flex: 1;

          svg {
            color: #cf740d;
          }

          p {
            color: #cf740d;
          }
        }
      }

      &.danger {
        border-color: #d54839;
        background-color: rgba(214, 72, 56, 0.15);

        .alert-icon {
          color: #d54839;
        }

        .alert-content {
          flex: 1;

          svg {
            color: #d54839;
          }

          p {
            color: #d54839;
          }
        }
      }
    }

    .main-table {
      tr.error {
        background-color: rgba(253, 57, 122, 0.15);
      }
    }

    .addresses-wrapper {
      display: flex;
      flex-flow: wrap;
      margin: 5px -5px 0;

      .dashboard {
        flex-basis: calc(33.3333% - 10px);
        max-width: calc(33.3333% - 10px);
        margin: 5px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(0.4)
  }

  100% {
    transform: scale(1)
  }
}